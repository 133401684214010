import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ProjectUserRoles } from "../../../constants";
import { useAuthStore } from "../../../store/hooks";

const generateLink = (role: ProjectUserRoles, pathname: string) => {
  if (role === ProjectUserRoles.ROLE_GUEST)
    return `/login?redirect=${encodeURIComponent(pathname)}`;
  else if (
    role === ProjectUserRoles.ROLE_ADMIN ||
    role === ProjectUserRoles.ROLE_AUDITOR
  )
    return "/clients";
  else return "/library";
};

export const Page404 = () => {
  const { role } = useAuthStore();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // const timer = setTimeout(() => {
    navigate(generateLink(role, location.pathname));
    // }, 3000);

    // return () => clearTimeout(timer);
  }, [navigate, role, location]);

  return null;
  // return (
  //   <Grid
  //     container
  //     height="100%"
  //     direction="column"
  //     justifyContent="center"
  //     alignItems="center"
  //   >
  //     <img
  //       src={logo}
  //       alt="logo"
  //       style={{
  //         width: "275px",
  //         height: "108px",
  //         marginBottom: "32px",
  //       }}
  //     />
  //     <Typography
  //       fontSize="96px"
  //       color="secondary.main"
  //       fontWeight="500"
  //       lineHeight="112px"
  //     >
  //       404
  //     </Typography>
  //     <Typography variant="body1" color="secondary.dark">
  //       Niestety nie udało nam się znaleźć tej strony.
  //     </Typography>
  //     <Typography color="secondary.dark" variant="caption">
  //       Po 3 sek. nastąpi przeniesienie na stronę główną.
  //     </Typography>
  //   </Grid>
  // );
};
